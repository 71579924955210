// IMPORT VENDOR
import $ from 'jquery';
window.$ = window.jQuery = $;

import Swiper, { Navigation, Pagination, Controller, EffectFade, Autoplay } from 'swiper';
Swiper.use([Navigation, Pagination, Controller, EffectFade, Autoplay]);

import Isotope from 'isotope-layout';
var jQueryBridget = require('jquery-bridget');

// IMPORT DES COMPONENTS
import { utils } from './components/_utils';

jQuery(function () {

    /*TEST DE COMPATIBILITE*/
    try {
        if (CSS.supports('display', 'flex')) {
            //alert('oui, supporté')
        } else {
            document.getElementsByClassName('wrapper')[0].style.display = 'none';
            document.getElementById('no-compatible').style.display = 'block';
        }
    } catch (error) {
        document.getElementsByClassName('wrapper')[0].style.display = 'none';
        document.getElementById('no-compatible').style.display = 'block';
    }


    $(".menu-trigger").on('click', function () {
        if (!$(this).hasClass('open')) {
            $("#small-sliding-menu").stop(true, false).slideDown(400);
            $(this).addClass('open');
        } else {
            $("#small-sliding-menu").stop(true, false).slideUp(300);
            $(this).removeClass('open');
        }
    });

    //SMALL SUBMENU
    $("li.menu-item-has-children").on('click', function () {

        if (!$(this).hasClass('open')) {
            $(this).addClass('open');
        } else {
            $(this).removeClass('open');
        }
    });

    $(window).on('scroll', function () {
        if ($(this).scrollTop() >= 100) {
            $('header').addClass('small');
        } else {
            $('header').removeClass('small');
        }
    });

    $("#loader-area").fadeOut("normal");


    if (!document.addEventListener) {
        document.getElementsByClassName('wrapper')[0].style.display = 'none';
        document.getElementById('no-compatible').style.display = 'block';
        console.log("no addEventListener");
    }

    function isIE() {
        var myNav = navigator.userAgent.toLowerCase();
        return (myNav.indexOf('msie') != -1) ? parseInt(myNav.split('msie')[1]) : false;
    }

    var isIE11 = !!navigator.userAgent.match(/Trident.*rv\:11\./);

    if (isIE() && isIE() <= 9) {
        document.getElementsByClassName('wrapper')[0].style.display = 'none';
        document.getElementById('no-compatible').style.display = 'block';
        console.log("IE <9");
    }


    var d = document.documentElement.style;
    if (('flexWrap' in d) || ('WebkitFlexWrap' in d) || ('msFlexWrap' in d)) {
    } else {
        document.getElementsByClassName('wrapper')[0].style.display = 'none';
        document.getElementById('no-compatible').style.display = 'block';
        console.log("No Flexbox compatibility");
    }


    //SECTION GSAP
    var controller = new ScrollMagic.Controller();
    var TLHeader = new TimelineMax();

    var sceneINMenu = new ScrollMagic.Scene({ triggerElement: "#body-main", triggerHook: 'onLeave', duration: "300px" })
        .setTween(TLHeader)
        .addTo(controller);

    var sections = document.querySelectorAll('section');
    for (var i = 0; i < sections.length; i++) {

        var TLSection = new TimelineMax();
        TLSection.fromTo(sections[i], .4, { opacity: 0 }, { opacity: 1 }, 0);

        new ScrollMagic.Scene({
            triggerElement: sections[i],
            triggerHook: "onEnter",
            duration: '60%'
        })
            .setTween(TLSection)
            .addTo(controller);
    }


    // --- Header > Search form ---
    $('#header #searchsubmit').on('click', function (e) {
        let $wrapper = $(this).parent();
        let $inputSearch = $wrapper.find('input[type=text]');

        if ($inputSearch.val() == '') {
            $inputSearch.toggleClass('open');
            e.preventDefault();
        }
    });


    // --- Home ---
    if ($('.wrapper#homepage').length) {
        var TLHeader = new TimelineMax();
        TLHeader.from($('.black-layer'), .8, { opacity: 0 }, .6)
            .from($('.home-presentation'), .5, { opacity: 0, y: "+=10" });

        var SwiperFrontNews = new Swiper('.swiper-frontnews', {
            speed: 6000,
            loop: true,
            slidesPerView: 1,
            spaceBetween: 30,
            autoplay: {
                delay: 0,
            },
            breakpoints: {
                640: {
                    slidesPerView: 2,
                },
                1024: {
                    slidesPerView: 3
                },
                1200: {
                    slidesPerView: 4
                }
            },
            on: {
                afterInit: function (swiper) {
                    swiper.autoplay.stop();
                }
            }
        });


        if ('IntersectionObserver' in window) {
            // IntersectionObserver Supported

            // Créé un observer
            let observer = new IntersectionObserver(onChangeFrontnews, {
                root: null,
                rootMargin: "300px 0px 0px",
                threshold: 1
            });
            let newsCarrousel = document.getElementsByClassName('swiper-frontnews')[0];

            // Attache l'observer à un élément
            observer.observe(newsCarrousel);
        }

        function onChangeFrontnews(changes, observer) {
            changes.forEach(change => {

                if (change.intersectionRatio == 1) { // Si l'élément est entièrement dans la fenêtre

                    SwiperFrontNews.autoplay.start();

                } else {

                    SwiperFrontNews.autoplay.stop();
                    SwiperFrontNews.slideTo(0, 0);
                    SwiperFrontNews.updateProgress();

                }
            });
        }
    }



    // --- Partenaires & Réseaux ---
    if ($('.page-template-page-partenaires-et-engagements').length) {
        const myCustomSlider = document.querySelectorAll('.swiper-container');
        var swipers = [];
        for (i = 0; i < myCustomSlider.length; i++) {
            myCustomSlider[i].classList.add('swiper-id-' + i);

            var slider = new Swiper('.swiper-id-' + i, {
                effect: 'creative',
                direction: 'horizontal',
                slidesPerView: 1,
                autoHeight: true,
                loop: true,
                navigation: {
                    nextEl: '.swiper-button-next-' + i,
                    prevEl: '.swiper-button-prev-' + i,
                },
                autoplay: {
                    delay: 2500,
                },
                breakpoints: {
                    480: {
                        slidesPerView: 3,
                        direction: 'vertical'
                    }
                },
                on: {
                    afterInit: function (swiper) {
                        swiper.autoplay.stop();
                    }
                }
            });

            swipers.push(slider);
        }

        if ('IntersectionObserver' in window) {
            // IntersectionObserver Supported

            // Créé un observer
            let observer = new IntersectionObserver(onChangePartners, {
                root: null,
                rootMargin: "300px 0px 0px",
                threshold: 1
            });

            // Attache l'observer à un élément
            Array.prototype.forEach.call(myCustomSlider, item => observer.observe(item));
        }


        function onChangePartners(changes, observer) {
            changes.forEach(change => {
                let carrousel = change.target;
                let carrouselId = carrousel.getAttribute('class').split('swiper-id-')[1];
                let carrouselNumber = parseInt(carrouselId.substring(0, 1));
                let swiperInstance = swipers[carrouselNumber];

                if (change.intersectionRatio == 1) { // Si l'élément est entièrement dans la fenêtre

                    swiperInstance.autoplay.start();

                } else {

                    swiperInstance.autoplay.stop();
                    swiperInstance.slideTo(0, 0);
                    swiperInstance.updateProgress();

                }
            });
        }
    }


    if ($('.wrapper#single-member').length) {

        var mySwiper = new Swiper('.swiper-member-actus', {
            speed: 400,
            spaceBetween: 30,
            autoplay: {
                delay: 2500,
            },
            navigation: {
                nextEl: '.swiper-button-actus-next',
                prevEl: '.swiper-button-actus-prev',
            },
            breakpoints: {
                380: {
                    slidesPerView: 1,
                },
                1024: {
                    slidesPerView: 3,
                }
            }
        });

    }


    // --- Page actualités ---
    if ($(".wrapper#actualites").length) {
        var size_li = $(".publication").length;
        var y = $(".publication:visible").length;
        var x = y;

        $("#see-more").on('click', function (e) {
            e.preventDefault();
            x = (x + y <= size_li) ? x + y : size_li;
            $('.publication:lt(' + x + ')').fadeIn();
        });

        $('.filter').on('click', function (e) {
            switch (this.id) {
                case "filterArticle":
                    $('.publication.article').show();
                    $('.publication.video').hide();
                    break;
                case "filterVideo":
                    $('.publication.article').hide();
                    $('.publication.video').show();
                    break;
                case "filterAll":
                    $('.publication.article').show();
                    $('.publication.video').show();
                    break;
            }
        });
    }

    // --- Single actualité ---
    if ($('#single-post').length) {
        if (utils.getDeviceKind() != 'isMobile') {
            var controller = new ScrollMagic.Controller();
            // build scene

            let contentHeight = $('#content .content').height();

            var scene = new ScrollMagic.Scene({ triggerElement: "#content", triggerHook: 0.17, duration: contentHeight })
                .setPin("#summary")
                .addTo(controller);
        }

        $(document).on('click', 'a[href^="#"]', function (event) {
            event.preventDefault();

            let link = $(this).attr('href');

            if (link != '#') {

                $('html, body').animate({
                    scrollTop: $(link).offset().top - 100
                }, 500);
            }
        });

        var isOpenVisible = $('#open').css('display') == "block";

        $(window).on('resize', function () {
            isOpenVisible = $('#open').css('display') == "block";
        });

        $('#summary-head').on('click', function (event) {
            event.preventDefault();
            if (isOpenVisible) {
                if (!$("#summary").hasClass('open')) {
                    $("#summary").addClass('open');
                } else {
                    $("#summary").removeClass('open');
                }
            }
        });
    }


    if ($(".wrapper#rejoignez-la-teamja").length) {

        var mySwiperteam = new Swiper('.swiper-team', {
            speed: 400,
            loop: true,
            slidesPerView: 1,
            navigation: {
                nextEl: '.swiper-button-team-next',
                prevEl: '.swiper-button-team-prev',
            },
            autoplay: {
                delay: 4000,
            }
        });

        var mySwiperQuotes = new Swiper('.swiper-quotes', {
            speed: 400,
            loop: true,
            slidesPerView: 1,
            centeredSlides: true,
            autoplay: {
                delay: 3000,
            },
            navigation: {
                nextEl: '.swiper-button-quotes-next',
                prevEl: '.swiper-button-quotes-prev',
            }
        });


    }


    // --- Page Expertises ---
    if ($(".wrapper#expertises").length) {

        if (window.location.hash) {
            $('.expertise').addClass('unfocused');
            var hash = window.location.hash;

            $(hash).removeClass('unfocused');

            $('html, body').animate({
                scrollTop: $(hash).offset().top - 88
            }, 1500, 'swing', function () {

                setTimeout(function () {
                    $('.expertise').removeClass('unfocused');
                }, 5000);
            });
        }
    }


    // --- Page Opérations ---
    if ($('.wrapper#operations').length) {
        function displayYearRef() {
            var items = {};

            $('div.operation').not('.closed').each(function () {
                items[$(this).attr('data-date')] = true;
            });

            $('.date-ref').remove();

            for (var i in items) {
                if (i !== "undefined") {
                    //Year reference
                    var myPos = $(".operation[data-date='" + i + "']").first().position();
                    $("#operations-grid-container").append("<div style='top:" + myPos.top + "px' class='date-ref'>" + i + "</div>");
                }
            }
        }

        displayYearRef();

        $(".btn-exp").on('click', function () {
            var exp = $(this).attr('data-exp');

            $(".operation.closed").removeClass('closed');
            $(".operation").not("." + exp).addClass('closed');
            $('.date-ref').remove();
            //Disable "plus" btn

            $(".btn-exp-all").css('display', 'block');
        });

        $(".btn-exp-all").on('click', function () {
            $(".operation.closed").removeClass('closed');
            displayYearRef();
            $(this).hide();
        });

        //Sort by URL
        if (window.location.hash) {
            var exp = window.location.hash.substring(1);

            $('.date-ref').remove();
            $(".operation").not("." + exp).addClass('closed');
            //$(".operation[data-exp!='"+exp+"']").addClass('closed');
        }

        // Display more
        var size_li = $(".operation:not('.closed')").length;
        var x = size_li;

        $("#see-more").click(function (e) {
            e.preventDefault();
            x = (x + 6 >= size_li) ? x + 6 : size_li;
            $('.operation:lt(' + x + ')').fadeIn().removeClass('closed');

            if ($(".operation.closed").length == 0) {
                $("#see-more").fadeOut();
            }
        });
    }


    $('#mail-handler').click(function () {
        $("#mail-form").toggleClass('opened');
        $("#mail-handler").toggleClass('opened');
    })


    $('.back-top').click(function () {
        $('html, body').animate({
            scrollTop: $(".wrapper").offset().top
        }, 1500, 'swing');
    })

    /* PAGE RECRUTEMENT ------------- */

    jQueryBridget('isotope', Isotope, $);

    console.log("recrutement wip");
    if ($('.page-template-page-recrutement').length) {
        $(".page-template-page-recrutement #introduction .section-title").on("mouseenter", function () {
            $(".page-template-page-recrutement .cell-information .actif").removeClass("actif");
            let section = $(this).attr("data-section");
            $(".cell-information .wrapper-texte." + section).addClass("actif");
        });

        $(".page-template-page-recrutement #introduction .section-title").on("mouseleave", function () {
            let section = $(this).attr("data-section");
            $(".cell-information .wrapper-texte." + section).removeClass("actif");
        });

        var $gridOffre = $('.grid-offre').isotope({
            itemSelector: '.cell-offre',
            layoutMode: 'fitRows'
        });

        $(".filtre .tag").on("click", function () {
            $(".filtre .tag.actif").removeClass("actif");
            $(this).addClass("actif");
            var filterValue = $(this).attr("data-filter");
            $gridOffre.isotope({
                filter: filterValue
            });
        });

        $("#filtre-offfre").on("change", function () {
            var filterValue = $(this).val();
            $gridOffre.isotope({
                filter: filterValue
            });
        });

        var mySwiper = new Swiper('.swiper-media', {
            loop: true,
            spaceBetween: 0,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });

        var swiperTemoignage = new Swiper('.swiper-temoignage', {
            loop: true,
            spaceBetween: 0,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }
    /* FIN RECRUTEMENT ---------------------- */


    $('#formQuickContact').submit(function (event) {
        event.preventDefault(); //prevent form submit before captcha is completed
        grecaptcha.execute();
    });

    var onCompleted = function (response) {
        form = $('form#formContact');
        $('button:submit, this').addClass('onClic');
        //e.preventDefault();

        var formdata = new FormData();

        formdata.append('action', 'send_contact_form');
        formdata.append('g-recaptcha-response', response);

        $.each(form.serializeArray(), function (i, field) {
            formdata.append(field.name, field.value);
        });

        $.ajax({
            url: adminAjax,
            method: 'POST',
            data: formdata,
            contentType: false,
            processData: false,
            success: function (response) {
                $('button:submit, this').removeClass('onClic');
                switch (response.data) {
                    case "success-mail":
                        Swal.fire(
                            'Merci !',
                            'Votre Message a bien été envoyé!',
                            'success'
                        );
                        form[0].reset();
                        break;
                    case "error-mail-exist":
                        Swal.fire(
                            'Erreur',
                            "Votre message n'a pas été envoyé, veuillez reessayer plus tard",
                            'warning'
                        );
                        break;
                    case "error-recaptcha":
                        Swal.fire(
                            'Attention...',
                            'N\'oubliez pas de cocher la case "je ne suis pas un Robot".',
                            'warning'
                        );
                        break;
                }
            },
            error: function (data) {
                console.log(data);
            }
        });

        function postSend() {
        }
    }
});